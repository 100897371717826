import { Dialog, Link, Typography, Grid, Card, CardHeader, CardActionArea, CardContent, Avatar, Backdrop, CircularProgress } from '@mui/material'
import React from 'react'
import { useHistory } from 'react-router-dom'
import './Jobs.css';
import { AppBar, Button, IconButton, useTheme } from "@mui/material";
import { EmailRounded, ExpandMore, Facebook, GamesRounded, HomeRounded, InfoRounded, Instagram, LocationCity, LocationOn, More, Twitter, YouTube } from "@mui/icons-material";
import { ReactComponent as PiziaLogoR } from "../../assets/logos/Pizia_IL_R.svg"
import { ReactComponent as PiziaLogoW } from "../../assets/logos/Pizia_IL_W.svg"
import JobCard from './JobCard/JobCard';
import { initializeApp } from "firebase/app";
import { getFirestore, collection, getDocs, query, where } from "firebase/firestore";
import { useState } from 'react';
import { useEffect } from 'react';
import { firestoreDB } from '../../firebase/firebase';
import { isMobile } from 'react-device-detect';

function Jobs(props) {

    const { link } = props;
    const history = useHistory();
    const theme = useTheme();
    const topBar = true;

    const [loading, setLoading] = useState(false);

    const getJobs = async () => {
        setLoading(true);
        const q = query(collection(firestoreDB, "jobs"), where("active", "==", true));
        const instantDocs = await getDocs(q);
        let docs = [];
        instantDocs.forEach((doc) => {
            docs = [...docs, doc.data()]
        });
        docs.forEach((doc) => {
            doc.description = doc.description.replace(/\\n/g, "\n");
        })
        setJobs(docs);
        setLoading(false);
    }

    useEffect(() => {
        getJobs();
    }, [])

    const [jobs, setJobs] = useState([]);

    // const jobs = [
    //     {
    //         title: "Marketing Developer | Video Creatives",
    //         location: "Cordoba, Argentina",
    //         mode: "Full-Time, In-House",
    //         description: "### What is a Marketing Developer? \n We are looking for someone who builds **Marketing Creatives**, using a **Game Developer** mindset and tools. \n ### What would I have to do on the job? \n We need you to create **Playables**, using the tech we have available to do so, which is a visual game engine (think unity!) based off javascript \n ### What's a Playable? \n It's a **small version of a game**, usually only one level, that is used as an ad for the entire game. \n ## Required Skills \n - asdasd \n - asdasfasf \n ## Useful (but not required) Skills \n - asdfgasdfg",
    //         formID: "1FAIpQLScGqX2YeFbdth-iVzWowr0u-sUPDhtw6a1XZasumDbaWYAVlw",
    //     },
    //     {
    //         title: "Marketing Developer | Playables",
    //         location: "Cordoba, Argentina",
    //         mode: "Full-Time, In-House",
    //         description: "### What is a Marketing Developer? \n We are looking for someone who builds **Marketing Creatives**, using a **Game Developer** mindset and tools. \n ### What would I have to do on the job? \n We need you to create **Playables**, using the tech we have available to do so, which is a visual game engine (think unity!) based off javascript \n ### What's a Playable? \n It's a **small version of a game**, usually only one level, that is used as an ad for the entire game. \n ## Required Skills \n - asdasd \n - asdasfasf",
    //         formID: "1FAIpQLScGqX2YeFbdth-iVzWowr0u-sUPDhtw6a1XZasumDbaWYAVlw",
    //     },
    // ]

    return (
        <div>
            <AppBar style={{ bottom: "auto", top: "0" }} color={topBar ? "primary" : "transparent"} elevation={topBar ? 2 : 0}>
                <div style={{ display: "flex", flexDirection: "horizontal", padding: "8px", height: "28px" }}>
                    <div style={{ width: "200px" }}>
                        <div style={{ width: "100px" }}>
                            <Link href="https://pizia.com/">
                                {
                                    topBar ?
                                        <PiziaLogoW
                                            style={{ maxHeight: "26px", marginTop: 2, marginBottom: -2 }}
                                        />
                                        :
                                        <PiziaLogoR
                                            style={{ maxHeight: "26px", marginTop: 2, marginBottom: -2 }}
                                        />
                                }
                            </Link>
                        </div>
                    </div>
                    <div style={{ display: isMobile ? "none" : "flex", flexGrow: 1, justifyContent: "center" }}>
                        <Typography variant="h6">
                            JOB OPENINGS
                        </Typography>
                    </div>
                    <div style={{ width: "200px", justifyContent: "flex-end", display: "flex", flexDirection: "row" }}>
                        <IconButton onClick={() => { window.open("https://twitter.com/PiziaStudios", "_blank") }} size="small" style={{ backgroundColor: "white", color: "#1DA1F2", marginLeft: 5, marginTop: -3, width: "32px", height: "32px" }}>
                            <Twitter />
                        </IconButton>
                        <IconButton onClick={() => { window.open("https://facebook.com/PiziaStudios", "_blank") }} size="small" style={{ backgroundColor: "white", color: "#4267B2", marginLeft: 5, marginTop: -3, width: "32px", height: "32px" }}>
                            <Facebook />
                        </IconButton>
                        <IconButton onClick={() => { window.open("https://instagram.com/PiziaStudios", "_blank") }} size="small" style={{ backgroundColor: "white", color: "#E1306C", marginLeft: 5, marginTop: -3, width: "32px", height: "32px" }}>
                            <Instagram />
                        </IconButton>
                        {/* <IconButton onClick={() => { window.open("https://twitter.com/PiziaStudios", "_blank") }} size="small" style={{ backgroundColor: "#FF0000", color: "white", marginLeft: 5, width: "32px", height: "32px" }}>
                            <YouTube />
                        </IconButton> */}
                    </div>
                </div>
            </AppBar >
            <div style={{ padding: isMobile ? "0px" : "20px 20px 0px 20px", height: "100vh", overflow: "scroll" }}>

                <Grid container style={{ marginTop: 50, marginBottom: 30 }}>
                    <Grid item xs />
                    <Grid item xs={12} lg={8} xl={6} >
                        {jobs.map((job, iJob) => {
                            return (
                                <JobCard key={iJob} job={job} number={iJob + 1} />
                            )
                        })}
                    </Grid>
                    <Grid item xs />
                </Grid>
            </div>
            <Backdrop
                open={loading}
            >
                <CircularProgress color="primary" />
            </Backdrop>
        </div >
    )
}

export default Jobs