import React, { useState } from 'react'
import { Dialog, Link, Typography, Grid, Card, CardHeader, CardActionArea, CardContent, Avatar, Collapse, CardActions, Button, IconButton, Backdrop, CircularProgress } from '@mui/material'
import { LocationOn, ExpandMore, ExpandLess, Close } from '@mui/icons-material'
import ReactMarkdown from 'react-markdown';
import "./JobCard.css";

function JobCard(props) {

    const { job, number } = props;
    const [expanded, setExpanded] = useState(false);
    const [formOpen, setFormOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    }
    return (
        <Card style={{ margin: 20, borderRadius: 20, }} elevation={3}>
            <CardActionArea
                style={{
                    backgroundPosition: "center", backgroundSize: "cover", backgroundImage: `url(${require("../../../assets/images/card_back.jpg")})`
                }}
                onClick={handleExpandClick}
            >
                <CardHeader
                    title={job.title}
                    action={expanded ? <ExpandLess /> : <ExpandMore />}
                />
                <CardHeader
                    avatar={<Avatar><LocationOn /></Avatar>}
                    title={job.location}
                    subheader={job.mode}
                />
            </CardActionArea>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent>
                    <ReactMarkdown className='foo' source={job.description} >{job.description}</ReactMarkdown>
                </CardContent>
                <CardActions>
                    <Button
                        variant="contained"
                        style={{ borderRadius: 50 }}
                        onClick={() => {
                            // setFormOpen(true);
                            // setLoading(true);
                            window.open(`https://docs.google.com/forms/d/e/${job.formID}/viewform`, "blank")
                        }}
                    >
                        I want to apply!
                    </Button>
                </CardActions>
            </Collapse>
            <Dialog
                open={formOpen}
                onClose={() => { setFormOpen(false) }}
                maxWidth="md"
                fullWidth
            >
                <div
                    style={{
                        display: "flex",
                        alignItems: "flex-end",
                        justifyContent: "center"
                    }}

                >
                    <Typography variant="h5" style={{ margin: 20 }}>
                        {job.title}
                    </Typography>
                    <IconButton color="primary" style={{ position: "absolute", right: 15, top: 15, backgroundColor: "white" }} onClick={() => { setFormOpen(false); }}>
                        <Close />
                    </IconButton>
                </div>
                <iframe
                    src={`https://docs.google.com/forms/d/e/${job.formID}/viewform?embedded=true`}
                    // width="640"
                    height="100000"
                    frameborder="0"
                    sty
                    onLoad={() => { setLoading(false) }}
                >
                    Loading Form...
                </iframe>
            </Dialog>
            <Backdrop
                style={{ zIndex: 1000000 }}
                open={loading}
            >
                <CircularProgress color="primary" />
            </Backdrop>
        </Card >
    )
}

export default JobCard