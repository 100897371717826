import "./GamesStyles.css";

import robotLogo from "../../../assets/games/robot_logo.png";
import robotBackground from "../../../assets/games/robot_background.png";
import scanLogo from "../../../assets/games/scan_logo.png";
import scanBackground from "../../../assets/games/scan_background.png";
import mowLogo from "../../../assets/games/mow_logo.png";
import mowBackground from "../../../assets/games/mow_background.jpg";
import forageLogo from "../../../assets/games/forage_logo.png";
import forageBackground from "../../../assets/games/forage_background.png";
import voodooLogo from "../../../assets/games/voodoo_logo.png";
import voodooBackground from "../../../assets/games/voodoo_background.png";
import voodooBGMobile from "../../../assets/games/voodoo_bg_mobile.png";
import hayakuBackground from "../../../assets/games/hayaku_background.png";
import hayakuLogo from "../../../assets/games/hayaku_logo.png";

import googleplayBtn from "../../../assets/buttons/googleplay.png";
import appstoreBtn from "../../../assets/buttons/appstore.svg";

import background from "../../../assets/images/background.png";
import SwipeableViews from "react-swipeable-views";
import { autoPlay, virtualize } from "react-swipeable-views-utils";
import { useWindowSize } from "../../../hooks/useWindowSize"
import GameComponent from "./GameComponent/GameComponent";
import { useNavbarHeights } from "../../../hooks/useNavbarHeights";
import { AppBar, Button, Dialog, DialogActions, DialogContent, DialogTitle, Fab, IconButton, Typography, useTheme } from "@mui/material";
import { useState, useEffect, useContext } from "react";
import { Apple, ArrowBack, ArrowForward, Close, Google, IosShare, IosShareOutlined, KeyboardReturnOutlined, PlayArrow } from "@mui/icons-material";
import { OsTypes, osName } from "react-device-detect";
import { Helmet } from "react-helmet-async";
import { useHistory, useLocation } from "react-router-dom";
import Hayaku from "./Hayaku/Hayaku";

function Games(props) {
    const windowSize = useWindowSize();
    const navbarHeights = useNavbarHeights();
    const theme = useTheme();
    const history = useHistory();

    const VirtualizedViews = virtualize(SwipeableViews);
    const [gameIndex, setGameIndex] = useState(0);
    const { listInnerRef, pageLockedRef } = props;

    const googlePlayLink = "https://play.google.com/store/apps/details";
    const googlePlayNative = "market://details";
    const appstoreLink = "https://apps.apple.com/us/app";

    const lockPage = () => { pageLockedRef.current = true }
    const unlockPage = () => { pageLockedRef.current = false }

    const onDialogClose = () => {
        setInfoGame(null);
        unlockPage();
        if (location.search !== "") {
            history.push("/games");
        }
    }

    const allGames = [
        {
            id: "hayaku",
            name: "Hayaku",
            description: "Uncover the secrets of a mysterious island!",
            actionBtnText: "Wishlist on Steam Now!",
            backgroundImg: hayakuBackground,
            logo: hayakuLogo,
            actionAlign: "flex-end",
            link: "https://store.steampowered.com/app/2402500/Hayaku_Island_of_Darkness/",
            // androidId: "?id=com.Pizia.VoodooDoll",
            // iosId: "/voodoo-doll/id1549293118",
            // ribbon: true,
            // ribbonTop: "15+ MILLION",
            // ribbonBot: "DOWNLOADS",
            show: true,
            moreInfo: <Hayaku onClose={() => { onDialogClose(); }} />
        },
        {
            id: "voodoo-doll",
            name: "Voodoo Doll",
            description: "Solve the problems of daily life using voodoo magic!",
            actionBtnText: "Download",
            backgroundImg: voodooBackground,
            bgMobile: voodooBGMobile,
            logo: voodooLogo,
            actionAlign: "flex-end",
            androidId: "?id=com.Pizia.VoodooDoll",
            iosId: "/voodoo-doll/id1549293118",
            ribbon: true,
            ribbonTop: "15+ MILLION",
            ribbonBot: "DOWNLOADS",
            // trophies: [
            //     { top: "#1 MOST DOWNLOADED", bot: "GOOGLE PLAY USA" },
            //     { top: "#1 MOST DOWNLOADED", bot: "GOOGLE PLAY USA" }
            // ],
            show: true,
        },
        {
            id: "forage-the-world",
            name: "Forage the World",
            description: "Explore a world full of resources to gather and dangers to overcome!",
            actionBtnText: "Download",
            backgroundImg: forageBackground,
            logo: forageLogo,
            actionAlign: "flex-end",
            androidId: "?id=com.RockALotGames.ForageTheWorld",
            iosId: "/forage-the-world/id1619561258",
            show: true,
        },
        {
            id: "mow",
            name: "Master of Wills",
            description: "Multiplayer  tug of war collectible card game",
            actionBtnText: "Download",
            backgroundImg: mowBackground,
            logo: mowLogo,
            actionAlign: "center",
            androidId: "?id=com.Stormcrest.MoW",
            iosId: "/master-of-wills/id1326291135",
            show: false,
        },
        {
            id: "yes-we-scan",
            name: "Yes we Scan!",
            description: "Find crimes and stop them in the silliest way!",
            actionBtnText: "Download",
            backgroundImg: scanBackground,
            logo: scanLogo,
            actionAlign: "flex-end",
            androidId: "?id=com.Pizia.YesWeScan",
            // iosId: "/master-of-wills/id1326291135",
            show: true,
        },
        {
            id: "robot-crush",
            name: "Robot Crush",
            description: "Destroy giant robots and collect awesome loot!",
            actionBtnText: "Download",
            backgroundImg: robotBackground,
            logo: robotLogo,
            actionAlign: "flex-end",
            androidId: "?id=com.rockalotgames.robotcrush",
            // iosId: "/master-of-wills/id1326291135",
            show: true,
        },
    ]

    const games = allGames.filter(x => x.show);

    const viewHeight = windowSize.height - navbarHeights.top - navbarHeights.bottom;

    const [storeDialogGame, setStoreDialogGame] = useState(null);
    const [storeDialogOpen, setStoreDialogOpen] = useState(false);

    const [infoGame, setInfoGame] = useState(null);
    const [infoObject, setInfoObject] = useState(null);

    const location = useLocation();
    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const gameValue = queryParams.get('id');
        if (!gameValue) return;
        const gameFound = games.find(x => x.id === gameValue);
        if (!gameFound) {
            history.push("/games");
            return;
        }
        const index = games.findIndex(x => x.id === gameValue);
        setGameIndex(index);
        if (gameFound.moreInfo) {
            setInfoGame(index);
            setInfoObject(gameFound.moreInfo);
            lockPage();
        } else {
            history.push("/games");
        }

    }, [])

    console.log(location);

    return (
        <div
            style={{ overflowY: "hidden", height: "100%", background: `url(${background})` }}
        // ref={listInnerRef}
        >
            <Helmet>
                <title>Pizia - Games</title>
            </Helmet>
            <Dialog
                open={storeDialogOpen}
                onClose={() => { setStoreDialogOpen(false) }}
                maxWidth="xs"
                fullWidth
            >
                <DialogTitle>
                    <div style={{ display: "flex", alignItems: "center" }} >
                        <Typography variant="h5">{games[storeDialogGame]?.name}</Typography>
                        <div style={{ display: "flex", flexGrow: 1 }} />
                        <IconButton onClick={() => { setStoreDialogOpen(false) }} ><Close /> </IconButton>
                    </div>
                </DialogTitle>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around", padding: 10, alignItems: "center" }}>
                    <a href={appstoreLink + games[storeDialogGame]?.iosId} target="_blank">
                        <img style={{ height: 63 }} alt="appstore" src={appstoreBtn} />
                    </a>
                    <a href={googlePlayLink + games[storeDialogGame]?.androidId} target="_blank">
                        <img style={{ height: 63 }} alt="google play" src={googleplayBtn} />
                    </a>
                </div>
                {/*
                    <DialogActions>
                        <Button
                            startIcon={<Apple />}
                            variant="outlined"
                            onClick={() => { window.open(appstoreLink + games[dialogGame].iosId, "_blank") }}
                        >
                            Download on the AppStore
                        </Button>
                        <Button
                            startIcon={<PlayArrow />}
                            variant="outlined"
                            onClick={() => { window.open(googlePlayLink + games[dialogGame].androidId, "_blank") }}
                        >
                            Get it on Google Play
                        </Button> 
                    </DialogActions>*/}

            </Dialog>
            <SwipeableViews style={{ height: "100%" }}
                enableMouseEvents
                resistance
                index={gameIndex}
                onChangeIndex={setGameIndex}
                animateTransitions={true}
                disableLazyLoading={true}
                ignoreNativeScroll={true}
            >
                {games.map((game, iGame) => {
                    const onButtonClick = () => {
                        const { link, androidId, iosId } = game;

                        if (link) {
                            window.open(link, "_blank");
                            return;
                        }

                        if (androidId && !iosId) {
                            if (osName === OsTypes.Android) {
                                window.open(googlePlayNative + androidId, "_blank");
                            } else {
                                window.open(googlePlayLink + androidId, "_blank");
                            }
                            return;
                        } else if (iosId && !androidId) {
                            window.open(appstoreLink + iosId, "_blank");
                            return;
                        }

                        if (osName === OsTypes.Android && androidId) {
                            window.open(googlePlayNative + androidId, "_blank");
                        } else if (osName === OsTypes.IOS && iosId) {
                            window.open(appstoreLink + iosId, "_blank");
                        } else {
                            setStoreDialogOpen(true);
                            setStoreDialogGame(iGame);
                        }
                    }

                    const bg = game.bgMobile && windowSize.height > windowSize.width ? game.bgMobile : game.backgroundImg;
                    return (
                        <GameComponent
                            key={iGame}
                            backgroundImg={bg}
                            logo={game.logo}
                            name={game.name}
                            description={game.description}
                            actionBtnText={game.actionBtnText}
                            actionAlign={game.actionAlign}
                            action={onButtonClick}
                            ribbon={game.ribbon}
                            ribbonTop={game.ribbonTop}
                            ribbonBot={game.ribbonBot}
                            trophies={game.trophies}
                            showMoreInfo={game.moreInfo !== null && game.moreInfo !== undefined}
                            onMoreInfoClick={() => {
                                setInfoGame(iGame);
                                setInfoObject(game.moreInfo);
                                lockPage();
                            }}
                        />
                    )
                })}
            </SwipeableViews >
            {
                // gameIndex > 0 &&
                <Fab
                    size="small"
                    style={{ position: "absolute", top: viewHeight / 2, left: "10px" }}
                    onClick={() => { setGameIndex(gameIndex - 1) }}
                    disabled={gameIndex === 0}
                // style={{ display: "inline" }}
                // variant="contained"
                >
                    <ArrowBack />
                </Fab>
            }
            {
                // gameIndex < games.length - 1 &&
                <Fab
                    size="small"
                    style={{ position: "absolute", top: viewHeight / 2, right: "10px" }}
                    onClick={() => { setGameIndex(gameIndex + 1) }}
                    disabled={gameIndex === games.length - 1}
                // style={{ display: "inline" }}
                // style={{ display: gameIndex === games.length - 1 ? "none" : "inline" }}
                // variant="contained"
                >
                    <ArrowForward />
                </Fab>
            }
            <AppBar color="transparent" elevation={0} style={{ width: "100vw", bottom: 10 + navbarHeights.bottom, top: "auto", justifyContent: "center", zIndex: 100, display: "flex" }}>
                <div style={{ width: "100vw", justifyContent: "center", display: "flex" }}>
                    {
                        games.map((game, iGame) => {
                            const active = iGame === gameIndex;
                            return (
                                <IconButton
                                    key={iGame}
                                    style={{ width: "5px", height: "5px", margin: "0px 5px 0px 5px", backgroundColor: active ? "white" : "gray" }}
                                    onClick={() => { setGameIndex(iGame) }}
                                    elevation={5}
                                />
                            )
                        })
                    }
                </div>
            </AppBar>
            <Dialog open={infoGame !== null} maxWidth="xl" onClose={onDialogClose} fullWidth style={{ zIndex: 100000 }} >
                {infoObject}
            </Dialog>
        </div >
    )
}

export default Games