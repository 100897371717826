import { initializeApp } from "firebase/app"
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyAgdqj8ChF4OqgkMlgaFsNLS_FovziNqjw",
    authDomain: "pizia-website.firebaseapp.com",
    projectId: "pizia-website",
    storageBucket: "pizia-website.appspot.com",
    messagingSenderId: "457798465550",
    appId: "1:457798465550:web:90631451fcbbb2ef70645f"
};

const firebaseApp = initializeApp(firebaseConfig);
const firestoreDB = getFirestore(firebaseApp);
const firebaseAuth = getAuth(firebaseApp);
const googleAuthProvider = new GoogleAuthProvider();

export { firebaseConfig, firebaseApp, firestoreDB, firebaseAuth, googleAuthProvider };