import { Typography } from '@mui/material'
import React from 'react'
import { useHistory } from 'react-router-dom'

function LinkRedirect(props) {

    const { link } = props;
    const history = useHistory();
    React.useEffect(() => {
        window.location.replace(link);
    }, [])
    return (
        <div>
        </div >
    )
}

export default LinkRedirect