import { blue } from "@mui/material/colors"
import { BrowserRouter, Switch, Route } from "react-router-dom";
import "./App.css"
import Main from "./pages/Main/Main";

// import { makeStyles } from '@mui/styles';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Privacy from "./pages/Privacy/Privacy";
import Jobs from "./pages/Jobs/Jobs";
import Bootcamps from "./pages/Bootcamps/Bootcamps";
import LinkRedirect from "./pages/LinkRedirect/LinkRedirect";
import { Helmet, HelmetProvider } from "react-helmet-async";
import AdminPanel from "./pages/AdminPanel/AdminPanel";

const theme = createTheme({
  palette: {
    primary: {
      main: '#dd413e',
    },
    secondary: {
      main: "#dd413e",
    }
  },
  typography: {
    fontWeightRegular: 500,
    fontWeightMedium: 700,
    fontWeightBold: 900,
    fontFamily: '"Montserrat", sans-serif',
    h1: {
      fontWeight: 900
    },
    h2: {
      fontWeight: 900
    },
    h3: {
      fontWeight: 900
    },
    h4: {
      fontWeight: 700
    },
    h5: {
      fontWeight: 700
    },

  },
});

// const useStyles = makeStyles((theme) => {
//   root: {
//     // some CSS that accesses the theme
//   }
// });

function App() {

  const redirects = [
    {
      path: "/press",
      link: "https://presskit.pizia.com"
    },
    {
      path: "/games/hayaku",
      link: "https://pizia.com/games?id=hayaku"
    },
    {
      path: "/hayaku",
      link: "https://store.steampowered.com/app/2402500/Hayaku_Island_of_Darkness/"
    },
    {
      path: "/HayakuDiscord",
      link: "https://discord.gg/pgHGezA73Q"
    },
    {
      path: "/eze",
      link: "https://pizia.com/vcards/ezequiel.png",
    },
    {
      path: "/ezequiel",
      link: "https://pizia.com/vcards/ezequiel.png",
    },
    {
      path: "/art",
      link: "https://drive.google.com/file/d/1iwMfIu7ifMufM8E_NxQ1Ev9OIctaYWPU/view",
    },
  ]

  const baseroutes = [
    // {
    //   name: "Jobs",
    //   path: "/jobs",
    //   component: <Main />
    // },
    // {
    //   name: "Internal",
    //   path: "/internal",
    //   component: <Main />
    // },
    {
      name: "Privacy",
      path: "/privacy",
      component: <Privacy />
    },
    {
      name: "Jobs",
      path: "/jobs",
      component: <Jobs />
    },
    {
      name: "Bootcamps",
      path: "/bootcamp",
      component: <Bootcamps />
    },
    {
      name: "Admin",
      path: "/admin",
      component: <AdminPanel />
    },
    {
      name: "Main",
      path: "/",
      component: <Main />
    },
  ]

  return (
    <>
      <HelmetProvider>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <Switch>
              {redirects.map(
                (redir, indexRedir) => {
                  return (
                    <Route key={indexRedir} path={redir.path}>
                      <LinkRedirect link={redir.link} />
                    </Route>
                  )
                }
              )}
              {baseroutes.map(
                (route, indexRoute) => {
                  return (
                    <Route key={indexRoute} path={route.path}>
                      {
                        route.component
                      }
                    </Route>
                  )
                }
              )}
            </Switch>
          </BrowserRouter>
        </ThemeProvider>
      </HelmetProvider>
    </>
  );
}

export default App;
