import "./AboutStyles.css";
import aboutUs from "../../../assets/images/about_us.png"
import { useWindowSize } from "../../../hooks/useWindowSize";
import { useNavbarHeights } from "../../../hooks/useNavbarHeights";
import { Grid, Typography, useTheme } from "@mui/material";
import { Helmet } from "react-helmet-async";

function About(props) {
    const windowSize = useWindowSize();
    const navbarHeights = useNavbarHeights();

    const mobile = windowSize.width < 900;

    const theme = useTheme();
    const handleScroll = props.handleScroll;
    const listInnerRef = props.listInnerRef;

    return (
        <div
            style={{
                overflowY: "scroll",
                marginTop: navbarHeights.top,
                height: windowSize.height - navbarHeights.bottom - navbarHeights.top
            }}
            onScroll={handleScroll}
            ref={listInnerRef}
        >
            <Helmet>
                <title>Pizia - About</title>
            </Helmet>
            <Grid container>
                <Grid item xs />
                <Grid container item xs={12} md={11} direction="row" alignItems="center" style={{ height: windowSize.height - navbarHeights.top - navbarHeights.bottom }} >
                    <Grid item xs={12} md={6}>
                        <div style={{ justifyContent: "center", textAlign: "center" }}>
                            <img alt="" src={aboutUs} style={{ maxWidth: mobile ? "90%" : "80%" }} />
                        </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <div style={{ justifyContent: "center", height: "100%" }}>
                            <Typography variant="h3" style={{ color: theme.palette.primary.main, padding: 10 }}>
                                ABOUT US
                            </Typography>
                            <Typography style={{ padding: 10 }}>
                                <b>Pizia</b> was founded in 2018 in Cordoba, Argentina, by people who envisioned a world where mobile games were as complex and profound as their PC and Console counterparts. Our focus at <b>Pizia</b> is to make high quality, beautiful looking and innovative games.
                            </Typography>
                        </div>
                    </Grid>
                </Grid>
                <Grid item xs />
            </Grid>
        </div>
    )
}

export default About