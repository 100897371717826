import React, { useState } from 'react'
import { Dialog, Link, Typography, Grid, Card, CardHeader, CardActionArea, CardContent, Avatar, Backdrop, CircularProgress, AppBar, Drawer, IconButton, ListItemIcon, useTheme, ChevronLeftIcon, ChevronRightIcon, List, ListItem, ListItemButton, InboxIcon, MailIcon, ListItemText, Divider, Button } from '@mui/material'
import { ReactComponent as PiziaLogoR } from "../../assets/logos/Pizia_IL_R.svg"
import { ReactComponent as PiziaLogoW } from "../../assets/logos/Pizia_IL_W.svg"
import { ChevronLeft, ChevronRight, Gamepad, Inbox, Keyboard, Mail, Person, Receipt, School, Work } from '@mui/icons-material';
import AdminJobs from './AdminJobs/AdminJobs';
import AdminBootcamps from './AdminBootcamps/AdminBootcamps';
import { useEffect } from 'react';

import { query, collection, getDocs, doc, getDoc } from "firebase/firestore"
import { firebaseAuth, firestoreDB, googleAuthProvider } from '../../firebase/firebase';
import { signInWithPopup } from 'firebase/auth';

function AdminPanel() {
    const topBar = true;
    const theme = useTheme();

    const allPages = [
        {
            name: "Jobs",
            icon: <Work />,
            component: <AdminJobs />,
            requiresSuper: false
        },
        {
            name: "Bootcamps",
            icon: <School />,
            component: <AdminBootcamps />,
            requiresSuper: false
        },
        {
            name: "Games",
            icon: <Gamepad />,
            component: null,
            requiresSuper: false
        },
        {
            name: "Users",
            icon: <Person />,
            component: null,
            requiresSuper: true
        },
    ]

    const links = [
        {
            name: "Tu Recibo",
            icon: <Receipt />,
            link: "https://admin.turecibo.com/",
        }
    ]

    const [currentPage, setCurrentPage] = useState(null);
    const [loggedUserEmail, setLoggedUserEmail] = useState("");
    const [loading, setLoading] = useState(false);

    const [isAdmin, setIsAdmin] = useState(false);
    const [isSuperAdmin, setIsSuperAdmin] = useState(false);

    const onStartAuth = () => {
        signInWithPopup(firebaseAuth, googleAuthProvider).then(async (data) => {
            setLoading(true);

            //check if user exists
            const q = query(doc(firestoreDB, "admins", data.user.email));
            const docSnap = await getDoc(q);
            setLoggedUserEmail(data.user.email);
            if (docSnap.exists()) {
                setIsAdmin(true);
                setIsSuperAdmin(docSnap.data().super);
            }
            setLoading(false);
        })
    }

    const pages = isSuperAdmin ? allPages : allPages.filter(x => !x.requiresSuper);

    return (
        <div>
            <AppBar style={{ bottom: "auto", top: "0" }} color={topBar ? "primary" : "transparent"} elevation={topBar ? 2 : 0} position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                <div style={{ display: "flex", flexDirection: "horizontal", padding: "8px", height: "28px" }} >
                    <div style={{ width: "200px" }}>
                        <div style={{ width: "100px" }}>
                            <Link href="https://pizia.com/">
                                {
                                    topBar ?
                                        <PiziaLogoW
                                            style={{ maxHeight: "26px", marginTop: 2, marginBottom: -2 }}
                                        />
                                        :
                                        <PiziaLogoR
                                            style={{ maxHeight: "26px", marginTop: 2, marginBottom: -2 }}
                                        />
                                }
                            </Link>
                        </div>
                    </div>
                    <div style={{ display: "flex", flexGrow: 1, justifyContent: "center" }}>
                        <Typography variant="h6">
                            ADMIN PANEL
                        </Typography>
                    </div>
                    <div style={{ width: "200px", justifyContent: "flex-end", display: "flex", flexDirection: "row" }}>
                        {/* <IconButton onClick={() => { window.open("https://twitter.com/PiziaStudios", "_blank") }} size="small" style={{ backgroundColor: "white", color: "#1DA1F2", marginLeft: 5, marginTop: -3, width: "32px", height: "32px" }}>
                            <Twitter />
                        </IconButton>
                        <IconButton onClick={() => { window.open("https://facebook.com/PiziaStudios", "_blank") }} size="small" style={{ backgroundColor: "white", color: "#4267B2", marginLeft: 5, marginTop: -3, width: "32px", height: "32px" }}>
                            <Facebook />
                        </IconButton>
                        <IconButton onClick={() => { window.open("https://instagram.com/PiziaStudios", "_blank") }} size="small" style={{ backgroundColor: "white", color: "#E1306C", marginLeft: 5, marginTop: -3, width: "32px", height: "32px" }}>
                            <Instagram />
                        </IconButton> */}
                        {/* <IconButton onClick={() => { window.open("https://twitter.com/PiziaStudios", "_blank") }} size="small" style={{ backgroundColor: "#FF0000", color: "white", marginLeft: 5, width: "32px", height: "32px" }}>
                            <YouTube />
                        </IconButton> */}
                    </div>
                </div>
            </AppBar >
            {loggedUserEmail && isAdmin ?
                <div style={{ height: "100vh", overflow: "scroll", backgroundColor: "#dddddd" }}>
                    <Drawer
                        sx={{
                            width: 250,
                            flexShrink: 0,
                            '& .MuiDrawer-paper': {
                                width: 250,
                                boxSizing: 'border-box',
                            },
                        }}
                        variant="permanent"
                        anchor="left"
                    >
                        <div style={{ height: 50 }}>
                            <IconButton onClick={() => { }}>
                                <ChevronLeft />
                            </IconButton>
                        </div>
                        <Divider />
                        <List>
                            {pages.map((page, index) => (
                                <ListItem key={index} disablePadding onClick={() => { setCurrentPage(index) }}>
                                    <ListItemButton>
                                        <ListItemIcon>
                                            {page.icon}
                                        </ListItemIcon>
                                        <ListItemText primary={page.name} />
                                    </ListItemButton>
                                </ListItem>
                            ))}
                        </List>
                        <Divider />
                        <List>
                            {links.map((link, index) => (
                                <ListItem key={index} disablePadding onClick={() => { window.open(link.link, "_blank") }}>
                                    <ListItemButton>
                                        <ListItemIcon>
                                            {link.icon}
                                        </ListItemIcon>
                                        <ListItemText primary={link.name} />
                                    </ListItemButton>
                                </ListItem>
                            ))}
                        </List>
                    </Drawer>
                    <div style={{ marginTop: 50, marginLeft: 250 }}>
                        {currentPage !== null && allPages[currentPage].component}
                    </div>
                </div>
                :
                <Backdrop open>
                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>

                        {loggedUserEmail && <Typography style={{ color: "white" }}>User {loggedUserEmail} is invalid. </Typography>}
                        <Button sx={{ m: 2 }} onClick={onStartAuth} style={{ borderRadius: 100 }} variant="contained">Login with Google</Button>
                    </div>
                </Backdrop>
            }
            <Backdrop open={loading}>
                <CircularProgress color="primary" />
            </Backdrop>
        </div >
    )
}

export default AdminPanel