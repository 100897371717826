import { Close } from '@mui/icons-material';
import { Button, DialogContent, Grid, IconButton, Typography } from '@mui/material';
import React from 'react'
import YoutubeEmbed from '../../../../components/youtube/YoutubeEmbed';
import { useWindowSize } from '../../../../hooks/useWindowSize';

function Hayaku(props) {

    const { onClose } = props;
    const back = require("../../../../assets/games/hayaku_background.png");
    const logo = require("../../../../assets/games/hayaku_logo.png");

    const gif1 = require("../../../../assets/games/hayaku_gif_1.gif");
    const gif2 = require("../../../../assets/games/hayaku_gif_2.gif");
    const gif3 = require("../../../../assets/games/hayaku_gif_3.gif");
    const gif4 = require("../../../../assets/games/hayaku_gif_4.gif");
    const screen1 = require("../../../../assets/games/hayaku_screen_1.jpg");
    const screen2 = require("../../../../assets/games/hayaku_screen_2.jpg");
    const screen3 = require("../../../../assets/games/hayaku_screen_3.jpg");
    const screen4 = require("../../../../assets/games/hayaku_screen_4.jpg");

    const windowSize = useWindowSize();

    const mobile = (windowSize.width < 900);
    return (
        <>
            <div
                style={{
                    backgroundImage: `url(${back})`,
                    width: "100%",
                    aspectRatio: mobile ? "2 / 1" : "5 / 1",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    display: "flex",
                    alignItems: "flex-end",
                    justifyContent: "center"
                }}

            >
                <img src={logo} style={{ maxWidth: mobile ? "65%" : "25%", margin: 10 }} />
                <IconButton color="primary" size='small' style={{ position: "absolute", right: 15, top: 15, backgroundColor: "white" }} onClick={() => { onClose(); }}><Close /></IconButton>
            </div>
            <DialogContent>
                <Grid container>
                    <Grid item padding={1} xs={12} lg={5}>
                        <Typography variant="h3" paragraph>
                            About the Game
                        </Typography>
                        <Typography paragraph>
                            Hayaku is a retro-inspired metroidvania game that pays homage to classic titles from the 8-bit era. With challenging platforming, engaging combat, and a captivating story!
                        </Typography>
                        <Typography variant="h5" paragraph>
                            The Story
                        </Typography>
                        <Typography paragraph>
                            Play as Hayaki, a skilled soldier and member of Reikan, an organization dedicated to investigating and stopping anomalous activities. Recently, a mysterious island has become home to dangerous and unpredictable creatures without any apparent reason, prompting Reikan to investigate the situation.
                        </Typography>
                        <Typography paragraph>
                            As a member of this elite team, Hayaki is uniquely qualified to navigate the treacherous terrain and uncover the truth behind the island's secrets. But his motivations run deeper than just fulfilling his duty to Reikan. He's searching for his missing brother, another member of the organization who disappeared on the island under mysterious circumstances.
                        </Typography>
                        <Typography paragraph>
                            Driven by a fierce desire to find his sibling, Hayaki will stop at nothing to unravel the island's secrets and uncover the truth behind his brother's disappearance.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} lg={7} padding={mobile ? 0 : 3}>
                        <YoutubeEmbed embedId='8GVt76-BQvE' />
                        <Button
                            fullWidth
                            style={{ minHeight: 60, minWidth: 200, borderRadius: 50, fontWeight: "bold", marginTop: 20 }}
                            variant="contained"
                            onClick={() => {
                                window.open("https://store.steampowered.com/app/2402500/Hayaku_Island_of_Darkness/", "_blank");
                                return;
                            }}
                        >
                            Wishlist on Steam!
                        </Button>
                    </Grid>
                    <Grid item container xs={12}>
                        <Grid padding={1} item xs={12}>
                            <Typography variant="h3" paragraph >Media</Typography>
                        </Grid>
                        <Grid padding={1} item xs={12} lg={3} ><img src={gif1} style={{ width: "100%" }} /></Grid>
                        <Grid padding={1} item xs={12} lg={3} ><img src={screen1} style={{ width: "100%" }} /></Grid>
                        <Grid padding={1} item xs={12} lg={3} ><img src={gif2} style={{ width: "100%" }} /></Grid>
                        <Grid padding={1} item xs={12} lg={3} ><img src={screen2} style={{ width: "100%" }} /></Grid>
                        <Grid padding={1} item xs={12} lg={3} ><img src={screen3} style={{ width: "100%" }} /></Grid>
                        <Grid padding={1} item xs={12} lg={3} ><img src={gif3} style={{ width: "100%" }} /></Grid>
                        <Grid padding={1} item xs={12} lg={3} ><img src={screen4} style={{ width: "100%" }} /></Grid>
                        <Grid padding={1} item xs={12} lg={3} ><img src={gif4} style={{ width: "100%" }} /></Grid>
                    </Grid>
                </Grid>
            </DialogContent>
        </>
    )
}

export default Hayaku