import { AppBar, BottomNavigation, BottomNavigationAction, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography, useTheme } from "@mui/material";
import { EmailRounded, Facebook, GamesRounded, HomeRounded, InfoRounded, Instagram, Twitter, YouTube } from "@mui/icons-material";
import { useState, useEffect, useCallback, useRef, useContext } from "react";
import { Route, useHistory, useLocation } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import { ReactComponent as PiziaLogo } from "../../assets/logos/Pizia_IL_R.svg"
import { useWindowSize } from "../../hooks/useWindowSize";
import Home from "./Home/Home";
import Games from "./Games/Games";
import About from "./About/About";
import Contact from "./Contact/Contact";
import "./MainStyles.css"
import { useSwipeable } from "react-swipeable";
import { OsTypes, osName } from "react-device-detect";

import mouseControl from "../../assets/images/control_mouse.png"
import arrowsControl from "../../assets/images/control_arrows.png"
import { Helmet } from "react-helmet-async";
import { PageLockContext } from "../../context/Context";

function Main() {
    const history = useHistory();
    const location = useLocation();
    const theme = useTheme();

    const aboutRef = useRef();
    const contactRef = useRef();

    const pageLocked = useRef(false);

    const routes = [
        {
            name: "home",
            path: "/",
            component: Home,
            value: "",
            icon: HomeRounded,
            scrollable: false,
            ref: null,
            showInMenu: true,
        },
        {
            name: "games",
            path: "/games",
            component: Games,
            value: "games",
            icon: GamesRounded,
            scrollable: false,
            ref: null,
            showInMenu: true,
        },
        {
            name: "about",
            path: "/about",
            component: About,
            value: "about",
            icon: InfoRounded,
            scrollable: true,
            ref: aboutRef,
            showInMenu: true,
        },
        // {
        //     name: "team",
        //     path: "/team",
        //     component: null,
        //     value: "team",
        //     icon: ContactsRounded
        // },
        {
            name: "contact",
            path: "/contact",
            component: Contact,
            value: "contact",
            icon: EmailRounded,
            scrollable: true,
            ref: contactRef,
            showInMenu: true,
        },
    ]


    const currentRoute = location.pathname;
    const windowSize = useWindowSize();

    const [pageDirection, setPageDirection] = useState("page-down");

    let smallTopBar = location.pathname !== "/";

    const mobileMenus = windowSize.width < 900;

    let scrollCooldown = false;

    let setCooldown = () => {
        scrollCooldown = true;

        setTimeout(() => {
            scrollCooldown = false;
        }, 400)
    }

    const handleKey = useCallback((event) => {
        if (scrollCooldown || pageLocked.current) {
            return;
        }

        let path = window.location.pathname;

        let actualRoute = routes.find(x => x.path === path);
        let actualRouteIndex = routes.findIndex(x => x.path === path);

        if (actualRoute.scrollable && !actualRoute.ref.current) {
            console.log("no inner ref");
            return;
        }
        if (!actualRoute) {
            console.log("no route");
            return;
        }

        let bot = false;
        let top = false;

        if (actualRoute.ref && actualRoute.ref.current) {
            const { scrollTop, scrollHeight, clientHeight } = actualRoute.ref.current;
            bot = (Math.ceil(scrollTop) + clientHeight >= scrollHeight);
            top = (scrollTop < 1);
        }

        if (event.key === "ArrowUp" && (!actualRoute.scrollable || top) && actualRouteIndex > 0) {
            setPageDirection("page-up")
            history.push(routes[actualRouteIndex - 1].path);
            closeControlsDialog();
            setCooldown();
        }
        if (event.key === "ArrowDown" && (!actualRoute.scrollable || bot) && actualRouteIndex < routes.length - 1) {
            setPageDirection("page-down")
            history.push(routes[actualRouteIndex + 1].path);
            closeControlsDialog();
            setCooldown();
        }
    }, []);

    const handleWheel = useCallback((event) => {

        if (scrollCooldown || pageLocked.current) {
            return;
        }

        let path = window.location.pathname;

        let actualRoute = routes.find(x => x.path === path);
        let actualRouteIndex = routes.findIndex(x => x.path === path);

        if (actualRoute.scrollable && !actualRoute.ref.current) {
            console.log("no inner ref");
            return;
        }
        if (!actualRoute) {
            console.log("no route");
            return;
        }

        let bot = false;
        let top = false;

        if (actualRoute.ref && actualRoute.ref.current) {
            const { scrollTop, scrollHeight, clientHeight } = actualRoute.ref.current;
            bot = (Math.ceil(scrollTop + clientHeight) >= scrollHeight);
            top = (scrollTop < 1);
        }

        if (event.deltaY < 0 && (!actualRoute.scrollable || top) && actualRouteIndex > 0) {
            setPageDirection("page-up")
            history.push(routes[actualRouteIndex - 1].path);
            closeControlsDialog();
            setCooldown();
        }
        if (event.deltaY > 0 && (!actualRoute.scrollable || bot) && actualRouteIndex < routes.length - 1) {
            setPageDirection("page-down")
            history.push(routes[actualRouteIndex + 1].path);
            closeControlsDialog();
            setCooldown();
        }
    }, []);

    useEffect(() => {
        document.addEventListener("keydown", handleKey, false);
        document.addEventListener("wheel", handleWheel, false);

        return () => {
            document.removeEventListener("keydown", handleKey, false);
            document.removeEventListener("wheel", handleWheel, false);
        };
    }, []);

    const swipeConfig = {}

    const swipeHandlers = useSwipeable({
        onSwipedDown: (eventData) => {
            if (scrollCooldown || pageLocked.current) {
                return;
            }
            let path = window.location.pathname;

            let actualRoute = routes.find(x => x.path === path);
            let actualRouteIndex = routes.findIndex(x => x.path === path);

            if (actualRoute.scrollable && !actualRoute.ref.current) {
                console.log("no inner ref");
                return;
            }
            if (!actualRoute) {
                console.log("no route");
                return;
            }

            let top = false;

            if (actualRoute.ref && actualRoute.ref.current) {
                const { scrollTop, scrollHeight, clientHeight } = actualRoute.ref.current;
                top = (scrollTop < 1);
            }

            if ((!actualRoute.scrollable || top) && actualRouteIndex > 0) {
                setPageDirection("page-up")
                history.push(routes[actualRouteIndex - 1].path);
                closeControlsDialog();
                setCooldown();
            }
        },
        onSwipedUp: (eventData) => {
            if (scrollCooldown || pageLocked.current) {
                return;
            }
            let path = window.location.pathname;

            let actualRoute = routes.find(x => x.path === path);
            let actualRouteIndex = routes.findIndex(x => x.path === path);

            if (actualRoute.scrollable && !actualRoute.ref.current) {
                console.log("no inner ref");
                return;
            }
            if (!actualRoute) {
                console.log("no route");
                return;
            }

            let bot = false;

            if (actualRoute.ref && actualRoute.ref.current) {
                const { scrollTop, scrollHeight, clientHeight } = actualRoute.ref.current;
                // console.log({ scrollTop, scrollHeight, clientHeight });
                bot = (Math.ceil(scrollTop) + clientHeight >= scrollHeight);
            }

            if ((!actualRoute.scrollable || bot) && actualRouteIndex < routes.length - 1) {
                setPageDirection("page-down")
                history.push(routes[actualRouteIndex + 1].path);
                closeControlsDialog();
                setCooldown();
            }
        },
        ...swipeConfig,
    })

    const [controlsDialogOpen, setControlsDialogOpen] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            const mobilePlatform = (osName === OsTypes.Android || osName === OsTypes.IOS || osName === OsTypes.WindowsPhone);
            if (!mobilePlatform && localStorage.getItem("ClosedControlsDialog") === null) {
                setControlsDialogOpen(true);
            }
        }, 500)
    }, [])

    const closeControlsDialog = () => {
        setControlsDialogOpen(false);
        localStorage.setItem("ClosedControlsDialog", "DONE");
    }

    return (
        <div {...swipeHandlers} style={{ overflowX: "hidden" }} >
            <Helmet>
                <title>Pizia</title>
                <meta
                    name="description"
                    content="We make awesome games!"
                />
                <link rel="icon" href='../../../public/favicon.ico' />
            </Helmet>
            <AppBar style={{ bottom: "auto", top: "0" }} color={smallTopBar ? "inherit" : "transparent"} elevation={smallTopBar ? 2 : 0}>
                <div style={{ display: "flex", flexDirection: "horizontal", padding: "8px", height: "28px" }}>
                    <div style={{ width: "200px" }}>
                        <PiziaLogo
                            style={{ maxHeight: "26px", marginTop: 2, marginBottom: -2, display: smallTopBar ? "block" : "none" }}
                        />
                    </div>
                    <div style={{ display: "flex", flexGrow: 1, justifyContent: "center" }}>
                        {
                            !mobileMenus && routes.map((route, iRoute) => {
                                const selected = location.pathname === route.path;
                                return (
                                    <Button
                                        key={iRoute}
                                        onClick={() => {
                                            setPageDirection(iRoute > routes.indexOf(routes.find(x => x.path === currentRoute)) ? "page-down" : "page-up")
                                            history.push(route.path);
                                        }}
                                        style={{
                                            color: selected ? theme.palette.primary.main : theme.palette.grey[600],
                                            borderRadius: 0
                                        }}
                                    >
                                        {route.name}
                                    </Button>
                                )
                            })
                        }
                    </div>
                    <div style={{ width: "200px", justifyContent: "flex-end", display: "flex", flexDirection: "row" }}>
                        <IconButton onClick={() => { window.open("https://twitter.com/PiziaStudios", "_blank") }} size="small" style={{ backgroundColor: "#1DA1F2", color: "white", marginLeft: 5, width: "32px", height: "32px" }}>
                            <Twitter />
                        </IconButton>
                        <IconButton onClick={() => { window.open("https://facebook.com/PiziaStudios", "_blank") }} size="small" style={{ backgroundColor: "#4267B2", color: "white", marginLeft: 5, width: "32px", height: "32px" }}>
                            <Facebook />
                        </IconButton>
                        <IconButton onClick={() => { window.open("https://instagram.com/PiziaStudios", "_blank") }} size="small" style={{ backgroundColor: "#E1306C", color: "white", marginLeft: 5, width: "32px", height: "32px" }}>
                            <Instagram />
                        </IconButton>
                        {/* <IconButton onClick={() => { window.open("https://twitter.com/PiziaStudios", "_blank") }} size="small" style={{ backgroundColor: "#FF0000", color: "white", marginLeft: 5, width: "32px", height: "32px" }}>
                            <YouTube />
                        </IconButton> */}
                    </div>
                </div>
            </AppBar >

            <div style={{ overflowX: "hidden" }}>
                {
                    routes.map(
                        (route, iRoute) => {
                            return (
                                <Route key={iRoute} exact path={route.path}>
                                    {({ match }) => {
                                        // console.log(match);
                                        return (
                                            <CSSTransition
                                                in={match != null}
                                                timeout={500}
                                                classNames={pageDirection}
                                                unmountOnExit
                                            >
                                                <div className="page">
                                                    <route.component
                                                        // handleScroll={handleScroll}
                                                        listInnerRef={route.ref}
                                                        pageLockedRef={pageLocked}
                                                    />
                                                </div>
                                            </CSSTransition>
                                        )
                                    }}
                                </Route>
                            )
                        }
                    )
                }
            </div>

            <Dialog open={controlsDialogOpen} fullWidth maxWidth="xs">
                <DialogTitle>
                    Navigate using...
                </DialogTitle>
                <DialogContent>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around" }}>
                        <div style={{ textAlign: "center" }}>
                            <img alt="keys" src={arrowsControl} style={{ height: 100 }} />
                            <Typography>
                                ...arrow keys!
                            </Typography>
                        </div>
                        <div style={{ textAlign: "center" }}>
                            <img alt="mouse" src={mouseControl} style={{ height: 100 }} />
                            <Typography>
                                ...scroll wheel!
                            </Typography>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeControlsDialog} variant="contained" style={{ borderRadius: 50 }}>
                        OK, got it!
                    </Button>
                </DialogActions>
            </Dialog>
            <AppBar style={{ bottom: 0, top: "auto", backgroundColor: "white", color: "black", display: mobileMenus ? "block" : "none" }}>
                {
                    mobileMenus ?
                        <BottomNavigation
                            value={currentRoute}
                        >
                            {
                                routes.map((route, iRoute) => {
                                    return (
                                        <BottomNavigationAction
                                            key={iRoute}
                                            icon={<route.icon />}
                                            label={route.name.toUpperCase()}
                                            value={route.path}
                                            onClick={() => {
                                                setPageDirection(iRoute > routes.indexOf(routes.find(x => x.path === currentRoute)) ? "page-down" : "page-up")
                                                history.push(route.path);
                                            }}
                                        />
                                    )
                                })
                            }
                        </BottomNavigation>
                        :
                        <div>a</div>
                }
            </AppBar>
        </div >
    )
}

export default Main
