import { Button, Container, IconButton, Typography, useTheme } from '@mui/material'
import React from 'react'
import { useWindowSize } from '../../../../hooks/useWindowSize';
import { useNavbarHeights } from '../../../../hooks/useNavbarHeights';
import "./GameComponentStyles.css"
import trophy from "../../../../assets/images/trophy.png";
import { Add, Info, InfoOutlined, InfoRounded, InfoSharp, PlusOne } from '@mui/icons-material';

function GameComponent(props) {
    const { backgroundImg, logo, name, description, pcLink, droidLink, iosLink, actionBtnText, actionAlign, action, ribbon, ribbonTop, ribbonBot, trophies, showMoreInfo, onMoreInfoClick } = props;
    const windowSize = useWindowSize();
    const navbarHeights = useNavbarHeights();

    const height = windowSize.height - navbarHeights.top - navbarHeights.bottom;

    const theme = useTheme();

    return (
        <div
            style={{
                overflow: "hidden",
                height: height,
                backgroundSize: "cover",
                backgroundImage: `url(${backgroundImg})`,
                paddingTop: navbarHeights.top
            }}
            className="cover"
        >
            <div style={{ zIndex: 3, top: 0, bottom: 0, left: 0, right: 0, }}>
                <div
                    style={{
                        zIndex: 5,
                        transform: "rotate(-0.1turn)",
                        position: "relative",
                        top: 30,
                        left: -330,
                        backgroundColor: theme.palette.primary.main,
                        color: "white",
                        width: "800px",
                        height: "64px",
                        marginBottom: "-64px",
                        display: ribbon ? "block" : "none",
                    }}
                >
                    <Typography variant="h6" style={{ textAlign: "center" }}>{ribbonTop}</Typography>
                    <Typography variant="h6" style={{ textAlign: "center" }}>{ribbonBot}</Typography>
                </div>
                <div
                    height="100%"
                    style={{
                        zIndex: 1,
                        opacity: "200%",
                        bottom: 0,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: actionAlign,
                        height: height,
                        alignItems: "center"
                    }}
                >
                    {
                        trophies && (
                            <div style={{ display: "flex" }}>
                                {
                                    trophies.map((trophyTexts, iTrophy) => {
                                        return (
                                            <div key={iTrophy} style={{ display: "flex", flexDirection: "column", alignItems: "center", margin: 5 }}>
                                                <img src={trophy} style={{ width: 120 }} />
                                                <Typography style={{ fontWeight: "bold", fontSize: 12, color: "white", textAlign: "center", textShadow: "0px 1px 2px #000000FF", maxWidth: windowSize.width - 40, textAlign: "center" }} variant="h6">
                                                    {trophyTexts.top}
                                                </Typography>
                                                <Typography style={{ fontWeight: "bold", fontSize: 12, marginTop: -5, color: "white", textAlign: "center", textShadow: "0px 1px 2px #000000FF", maxWidth: windowSize.width - 40, textAlign: "center" }} variant="h6">
                                                    {trophyTexts.bot}
                                                </Typography>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        )
                    }
                    {/* {trophies && trophies.map(() => {
                        return (
                        )
                    })} */}
                    <Typography variant="h4" style={{ fontWeight: "bold", color: "white", textAlign: "center" }}>
                        <img alt={name} src={logo} style={{ maxHeight: "20vh", maxWidth: "80vw" }} />
                    </Typography>
                    <Typography paragraph style={{ color: "white", fontWeight: "bold", textAlign: "center", textShadow: "0px 2px 4px #000000FF", maxWidth: windowSize.width - 40, textAlign: "center" }} variant="h6">
                        {description}
                    </Typography>
                    <Typography style={{ color: "white", fontWeight: "bold", textAlign: "center", textShadow: "2px 2px #000000" }} variant="h6">
                        <Button
                            variant="contained"
                            color="primary"
                            style={{ minHeight: 60, minWidth: 200, borderRadius: 50, fontWeight: "bold" }}
                            onClick={action}
                        >
                            {actionBtnText}
                        </Button>
                        {
                            showMoreInfo &&
                            <IconButton
                                onClick={onMoreInfoClick}
                                style={{ width: 60, height: 60, backgroundColor: "white", marginLeft: 10 }}
                                color="primary"
                            >
                                <InfoOutlined />
                            </IconButton>
                            // <Button
                            //     variant="outlined"
                            //     color="primary"
                            //     style={{ minHeight: 60, minWidth: 200, borderRadius: 50, fontWeight: "bold" }}
                            //     onClick={action}
                            // >
                            //     +
                            // </Button>
                        }
                    </Typography>
                    <div style={{ height: 40 }} />
                </div>
            </div>
        </div>
    )
}

export default GameComponent
