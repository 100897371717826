import "./ContactStyles.css";
import contactUs from "../../../assets/images/contact_us.png"
import { useWindowSize } from "../../../hooks/useWindowSize";
import { useNavbarHeights } from "../../../hooks/useNavbarHeights";
import { Grid, Typography, useTheme } from "@mui/material";
import { Helmet } from "react-helmet-async";

function Contact(props) {
    const windowSize = useWindowSize();
    const navbarHeights = useNavbarHeights();

    const mobile = windowSize.width < 900;

    const theme = useTheme();

    const handleScroll = props.handleScroll;

    const listInnerRef = props.listInnerRef;

    return (
        <div
            onScroll={handleScroll}
            style={{
                overflowY: "scroll",
                marginTop: navbarHeights.top,
                height: windowSize.height - navbarHeights.bottom - navbarHeights.top
            }}
            ref={listInnerRef}
        >
            <Helmet>
                <title>Pizia - Contact</title>
            </Helmet>
            <Grid container>
                <Grid item xs />
                <Grid container item xs={12} md={11} direction="row" alignItems="center" style={{ height: windowSize.height - navbarHeights.top - navbarHeights.bottom }} >
                    {
                        mobile &&
                        <Grid item xs={12} md={6}>
                            <div style={{ justifyContent: "center", textAlign: "center" }}>
                                <img alt="" src={contactUs} style={{ maxWidth: "90%" }} />
                            </div>
                        </Grid>
                    }
                    <Grid item xs={12} md={6}>
                        <div style={{ justifyContent: "center", height: "100%" }}>
                            <Typography variant="h3" style={{ color: theme.palette.primary.main, padding: 10 }}>
                                CONTACT US
                            </Typography>
                            <Typography style={{ padding: 10 }}>
                                Do you want to talk to us? Ask us a question? Have feedback? Well, you can do just about any of those, by sending us your thoughts to our email, <b>contact@pizia.com</b> or our social media links at the top right. We'll be right back at you as soon as we can!
                            </Typography>
                        </div>
                    </Grid>
                    {
                        !mobile &&
                        <Grid item xs={12} md={6}>
                            <div style={{ justifyContent: "center", textAlign: "center" }}>
                                <img alt="" src={contactUs} style={{ maxWidth: mobile ? "90%" : "80%" }} />
                            </div>
                        </Grid>
                    }
                </Grid>
                <Grid item xs />
            </Grid>
        </div>
    )
}

export default Contact