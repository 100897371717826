import { useEffect, useState } from "react";

export function useNavbarHeights() {
    const [navbarHeights, setNavbarHeights] = useState({
        top: 0,
        bottom: 0,
    });
    useEffect(() => {
        function handleResize() {
            const mobile = window.innerWidth < 900;
            setNavbarHeights({
                top: 44,
                bottom: mobile ? 56 : 0
            });
        }

        // Add event listener
        window.addEventListener("resize", handleResize);
        // Call handler right away so state gets updated with initial window size
        handleResize();
        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount
    return navbarHeights;
}