import "./HomeStyles.css";
import { ReactComponent as PiziaLogo } from "../../../assets/logos/Pizia_IL_R.svg"
import { Box } from "@mui/material";
import backgroundImg from "../../../assets/images/background.png";
import { Helmet } from "react-helmet-async";

function Home(props) {
    const listInnerRef = props.listInnerRef;
    return (
        <div>
            <Helmet>
                <title>Pizia - Home</title>
            </Helmet>
            <div
                style={{ height: "100vh", backgroundImage: `url(${backgroundImg})` }}
                ref={listInnerRef}
            >
                <Box
                    textAlign="center"
                >
                    <PiziaLogo style={{ marginTop: "40vh", maxHeight: "20vh", maxWidth: "80vw" }} />
                </Box>
            </div>
        </div>
    )
}

export default Home