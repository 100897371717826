import React from 'react'
import { firestoreDB } from '../../../firebase/firebase'
import { collection, getDocs, query, setDoc, doc, deleteDoc } from "firebase/firestore";
import { useState, useEffect } from 'react';
import { Avatar, Backdrop, Button, Card, CardActionArea, CardActions, CardHeader, CircularProgress, Dialog, DialogActions, DialogContent, FormControl, FormGroup, FormLabel, Grid, IconButton, Input, Switch, TextField, ToggleButton, Typography } from '@mui/material';
import { Add, Check, Close, ContentCopy, Delete, Edit, FileCopy, More, PlusOne } from '@mui/icons-material';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';

function AdminBootcamps() {
    const [loading, setLoading] = useState(false);
    const [jobs, setJobs] = useState([]);

    const [editingID, setEditingID] = useState(null);
    const [editing, setEditing] = useState(false);

    const [deletingID, setDeletingID] = useState(null);

    const [editDescription, setEditDescription] = useState(null);
    const [editTitle, setEditTitle] = useState(null);
    const [editLocation, setEditLocation] = useState(null);
    const [editMode, setEditMode] = useState(null);
    const [editFormID, setEditFormID] = useState(null);
    const [editActive, setEditActive] = useState(true);

    const canSave = editTitle && editLocation && editMode && editFormID;

    const onConfirmDelete = async () => {
        setLoading(true);
        const del = deletingID;
        setDeletingID(null);
        await deleteDoc(doc(firestoreDB, "bootcamps", del));
        setLoading(false);
        getJobs();
    }

    const onConfirmEdit = async () => {
        setLoading(true);
        setEditing(false);
        await setDoc(doc(firestoreDB, "bootcamps", editingID ? editingID : crypto.randomUUID()), {
            active: editActive,
            title: editTitle,
            description: editDescription,
            location: editLocation,
            mode: editMode,
            formID: editFormID
        })
        setLoading(false);
        getJobs();
    }

    const onDuplicate = async (iJob) => {
        setLoading(true);
        const job = jobs[iJob];
        await setDoc(doc(firestoreDB, "bootcamps", crypto.randomUUID()), {
            active: false,
            title: job.title + " (copy)",
            description: job.description,
            location: job.location,
            mode: job.mode,
            formID: job.formID
        })
        setLoading(false);
        getJobs();
    }

    const onClickPostNewOpening = () => {
        setEditActive(true);
        setEditDescription("* Type some Markdown on the left \n * See HTML in the right \n * ✨Magic ✨ \n ## Features \n \n - Import a HTML file and watch it magically convert to Markdown \n - Drag and drop images (requires your Dropbox account be linked) \n - Import and save files from GitHub, Dropbox, Google Drive and One Drive \n - Drag and drop markdown and HTML files into Dillinger \n - Export documents as Markdown, HTML and PDF \n  \n Markdown is a lightweight markup language based on the formatting conventions \n that people naturally use in email. \n ``` \n npm install --production \n NODE_ENV=production node app \n ```");
        setEditFormID("");
        setEditTitle("");
        setEditLocation("Córdoba, Argentina");
        setEditMode("In-House, Full-Time");

        setEditing(true);
        setEditingID(null);
    }

    const onCloseEditing = () => {
        setEditing(false);
        setEditingID(null);
    }

    const getJobs = async () => {
        setLoading(true);
        const q = query(collection(firestoreDB, "bootcamps"));
        const instantDocs = await getDocs(q);
        let docs = [];
        instantDocs.forEach((doc) => {
            docs = [...docs, { ...doc.data(), id: doc.id }]
        });
        docs.forEach((doc) => {
            doc.description = doc.description.replace(/\\n/g, "\n");
        })
        setJobs(docs);
        setLoading(false);
    }

    useEffect(() => {
        getJobs();
    }, [])

    return (
        <div>
            <Typography variant="h5" sx={{ margin: 5 }} >
                {jobs.length} bootcamps open</Typography>
            <Grid container px={2}>
                {jobs.map((job, iJob) => {
                    const onClickEdit = () => {
                        setEditing(true);
                        setEditingID(job.id);
                        setEditTitle(job.title);
                        setEditDescription(job.description);
                        setEditLocation(job.location);
                        setEditActive(job.active);
                        setEditFormID(job.formID);
                        setEditMode(job.mode);
                    }

                    const onClickDuplicate = () => {
                        onDuplicate(iJob);
                    }

                    const avatar =
                        job.active ?
                            <Avatar style={{ backgroundColor: "green" }}><Check /> </Avatar>
                            :
                            <Avatar style={{ backgroundColor: "red" }}><Close /> </Avatar>
                        ;
                    return (
                        <Grid item key={iJob} xs={12} md={6}>
                            <Card sx={{ margin: 1 }}>
                                <CardHeader
                                    avatar={avatar}
                                    title={<Typography variant="h5">{job.title}</Typography>}
                                    subheader={job.id}
                                    action={
                                        <>
                                            <IconButton onClick={onClickEdit}>
                                                <Edit />
                                            </IconButton>
                                            <IconButton onClick={onClickDuplicate}>
                                                <FileCopy />
                                            </IconButton>
                                            <IconButton onClick={() => { setDeletingID(job.id) }}>
                                                <Delete />
                                            </IconButton>
                                        </>
                                    }
                                />
                            </Card>
                        </Grid>
                    )
                })}
                <Grid item xs={12} md={6} p={1}>
                    <Button
                        onClick={onClickPostNewOpening}
                        color="inherit"
                        fullWidth
                        startIcon={<Add />}
                        variant="outlined"
                    >
                        Post a new bootcamp
                    </Button>
                </Grid>
            </Grid>
            <Backdrop
                open={loading}
            >
                <CircularProgress color="primary" />
            </Backdrop>
            <Dialog open={deletingID !== null} fullWidth maxWidth="sm">
                <DialogContent>
                    Want to delete {deletingID} ?
                </DialogContent>
                <DialogActions >
                    <Button onClick={() => { setDeletingID(null) }}>
                        NO
                    </Button>
                    <Button onClick={onConfirmDelete}>
                        YES
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog maxWidth="xl" fullWidth open={editing}>
                <div style={{ display: "flex", padding: 10 }}>
                    <Typography variant="h5">
                        {
                            editingID === null ?
                                "Post new job opening"
                                :
                                "Editing " + editingID
                        }
                    </Typography>
                    <div style={{ flexGrow: 1 }} />
                    <IconButton onClick={onCloseEditing}>
                        <Close />
                    </IconButton>
                </div>
                <DialogContent>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <TextField margin='normal' required sx={{ width: "85%", marginRight: 2 }} label="Title" value={editTitle} onChange={(e) => { setEditTitle(e.target.value) }} />
                        <FormControl label="Active" required sx={{ marginRight: 1 }} value={editActive} >
                            <FormLabel>Active</FormLabel>
                            <Switch margin='normal' label="Active" checked={editActive} onChange={(e) => { setEditActive(e.target.checked) }} />
                        </FormControl>
                    </div>
                    <div style={{ display: "flex" }}>

                        <TextField margin='normal' sx={{ marginRight: 1, width: "42%" }} required label="Location" value={editLocation} onChange={(e) => { setEditLocation(e.target.value) }} />
                        <TextField margin='normal' sx={{ marginRight: 1, width: "42%" }} required label="Mode" value={editMode} onChange={(e) => { setEditMode(e.target.value) }} />
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <TextField margin='normal' required sx={{ marginRight: 1, width: "85%" }} label="Form ID" value={editFormID} onChange={(e) => { setEditFormID(e.target.value) }} />
                        <Button variant="outlined" sx={{ height: 40 }} onClick={() => { window.open(`https://docs.google.com/forms/d/e/${editFormID}/viewform?embedded=true`, "_blank") }}>
                            Check
                        </Button>
                    </div>
                    <div style={{ display: "flex" }}>
                        <TextField margin='normal' multiline sx={{ marginRight: 1, width: "48%" }} label="Description (markdown)" value={editDescription} onChange={(e) => { setEditDescription(e.target.value) }} />
                        <div style={{ margin: 10, width: "48%", maxWidth: "48%" }}>
                            <ReactMarkdown className='foo' source={editDescription} >{editDescription}</ReactMarkdown>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions >
                    <Button onClick={onCloseEditing}>
                        CANCEL
                    </Button>
                    <Button onClick={onConfirmEdit} disabled={!canSave}>
                        SAVE
                    </Button>
                </DialogActions>
            </Dialog>
        </div >
    )
}

export default AdminBootcamps